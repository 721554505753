// src/api/index.js

import client from './internal/httpClient';

// 示例请求函数，你可以根据自己的需求修改和添加
export function ping() {
  return client.get("/ping/index");
}

export const fetchPosts = () => {
  return client.get('/posts');
};

// 其他 API 请求函数...
